import React, { useState, useEffect, lazy, Suspense } from "react";
import {BrowserRouter as Router, Route, Routes, Navigate,} from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
const Preloader = lazy(() => import("./components/Pre"));
const Navbar = lazy(() => import("./components/Navbar"));
const Home = lazy(() => import("./components/Home/Home"));
const About = lazy(()=> import("./components/About/About"))
const Projects = lazy(()=> import("./components/Projects/Projects"))
const ScrollToTop = lazy(() => import("./components/ScrollToTop"));
function App() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      </Suspense>
    </Router>
  );
}

export default App;
